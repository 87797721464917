// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  FileProtectOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  HomeOutlined, 
  SolutionOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  FileProtectOutlined,
  HomeOutlined, 
  SolutionOutlined,
  UsergroupAddOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const adminMenu = {
    id: 'adminMenu',
    title: <FormattedMessage id="Admin" />,
    type: 'group',
    children: [
      
      {
        id: 'admin-user-list',
        title: <FormattedMessage id="admin-user-list" />,
        type: 'item',
        url: '/user-list-view',
        icon: icons.UsergroupAddOutlined
      },
    ]
};

export default adminMenu;
