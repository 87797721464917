import adminMenu from './adminMenu';
import other from './other';
import profile from './profile';
import fpaMenu from './fpaMenu';
import growerMenu from './growerMenu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [other, adminMenu, profile, fpaMenu, growerMenu]
};

export default menuItems;
