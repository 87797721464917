import { createSlice } from '@reduxjs/toolkit';

// project imports
import rackAxiosServices from 'utils/rackAxiosServices';

import { dispatch } from '../index';

const initialState = {
  error: null,
  fpauuid: '',
  agronomistuuid: '',
  groweruuid: '',
  initialFPAData: {},
  seedBedPrep: {
    data: {},
    scores: {}
  },
  nutrition: {
    data: {},
    scores: {},
  },
  seedQuality: {
    data: {},
    scores: {},

  },
  seedDepth: {
    data: {},
    scores: {},
  },
  seedingDate: {
    data: {},
    scores: {},
  },
  pestControl: {
    data: {},
    scores: {},
  },
  summarySection: {
    data: {},
    scores: {},
    predictions: {},
    fullFieldPrediction: {},
    zonePredictions: []
  },
  // get all fpas
  fpas: [],
  growerFpas: [],
  alerts: {}
};

const fpa = createSlice({
  name: 'fpa',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    clearCurrentFPAData(state, action) {
      state.seedBedPrep = { data: {}, scores: {} };
      state.seedQuality = { data: {}, scores: {} };
      state.seedingDate = { data: {}, scores: {} };
      state.seedDepth = { data: {}, scores: {} };
      state.pestControl = { data: {}, scores: {} };
      state.nutrition = { data: {}, scores: {} };
    },
    // ADD Initial FPA Information
    addInitialFPASucess(state, action) {
      state.initialFPAData = action.payload.initialFPAData;
      state.fpauuid = action.payload.fpauuid;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;

    },
    // UPDATE Initial FPA Information
    updateInitialFPASuccess(state, action) {
      state.seedBedPrep.data = action.payload.updateInformation;
      state.fpauuid = action.payload.fpauuid;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;
    },
    // UPDATE Seed Bed Prep Section and Scores
    updateSeedBedPrepSuccess(state, action) {
      state.seedBedPrep.data = action.payload.updateInformation;
    },
    getScoreSuccess(state, action) {
      //console.log('Section: ', action.payload.section)
      //console.log('subsection', action.payload.subsection)
      state[action.payload.section].scores[action.payload.subsection + 'Score'] = action.payload.score;
    },
    resetScores(state, action) {
      state[action.payload.section].scores = action.payload.data;
    },
    saveFpaSection(state, action) {
      state[action.payload.section].data = action.payload.data;
    },
    saveFpaSubsection(state, action) {
      state[action.payload.section].data[action.payload.subsection] = action.payload.data;
    },
    //UPDATE Nutrition Section and Scores
    updateNutritionSuccess(state, action) {
      state.nutrition.data = action.payload.updateInformation;
    },
    //UPDATE Seed Quality Section and Scores
    updateSeedQualitySuccess(state, action) {
      state.seedQuality.data = action.payload.updateInformation;
    },
    //UPDATE Seed Depth Section and Scores
    updateSeedDepthSuccess(state, action) {
      state.seedDepth.data = action.payload.updateInformation;
    },
    //UPDATE Seeding Date Section and Scores
    updateSeedingDateSuccess(state, action) {
      state.seedingDate.data = action.payload.updateInformation;
    },
    //UPDATE Pest Control Section and Scores
    updatePestControlSuccess(state, action) {
      state.pestControl.data = action.payload.updateInformation;
    },
    updateFpaSuccess(state, action) {
      state[action.payload.updateField] = action.payload.updateInformation;
    },
    //DELETE one FPa
    deleteFpaSuccess(state, action) {
      state.fpa = action.payload;
      state.error = null;
      //console.log('SUCCESS: ' + action.payload);
    },
    //GET one FPas
    getFpaSuccess(state, action) {
      state.fpauuid = action.payload.fpauuid;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;
      state.initialFPAData = action.payload.initialFPAData;
      state.seedBedPrep = action.payload.seedBedPrep;
      state.seedQuality = action.payload.seedQuality;
      state.seedingDate = action.payload.seedingDate;
      state.seedDepth = action.payload.seedDepth;
      state.pestControl = action.payload.pestControl;
      state.nutrition = action.payload.nutrition;
      state.summarySection = action.payload.summarySection;

      state.error = null;
      //console.log('SUCCESS: ' + action.payload);
    },
    getFpasSuccess(state, action) {
      state.fpas = action.payload;
      state.error = null;
    },
    getGrowerFpasSuccess(state, action) {
      state.growerFpas = action.payload;
      state.error = null;
    },
    saveTotalScore(state, action) {
      state[action.payload.section].scores.totalScore = action.payload.score;
    },
    getYieldDataPredictionSuccess(state, action) {
      // console.log('Yield Payload: ', action.payload)
      state.summarySection.fullFieldPrediction = action.payload.prediction;
    },
    updatePredictions(state, action) {
      //console.log('Yield Payload: ', action.payload);
      return {
        ...state,
        summarySection: {
          ...state.summarySection,
          zonePredictions: action.payload
        }
      }
    }
  }
});

// Reducer
export default fpa.reducer;
export const { clearCurrentFPAData } = fpa.actions;

export function deleteFpa(fpaUuid) {
  return async () => {
    try {
      // console.log('calling delete fpa');
      const url = process.env.REACT_APP_FPA_API_URL + '/' + fpaUuid;
      const response = await rackAxiosServices.delete(url);
      // console.log('response: ', response);
      //dispatch(fpa.actions.deleteFpaSuccess(response.data[0]));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getFpa(fpaUuid) {
  return async () => {
    try {
      const url = process.env.REACT_APP_FPA_API_URL + '/' + fpaUuid;
      const response = await rackAxiosServices.get(url);
      dispatch(fpa.actions.getFpaSuccess(response.data[0]));
    } catch (error) {

      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      console.log(`Error from axios call ${JSON.stringify(axiosError)}`);

      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getFpasByGrowerId(growerUuid) {
  return async () => {
    try {
      const url = process.env.REACT_APP_FPA_API_URL + '/grower/' + growerUuid;
      const response = await rackAxiosServices.get(url);
      //console.log('response', response.data)
      dispatch(fpa.actions.getGrowerFpasSuccess(response.data));

    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getFpas(agronomistUUID) {
  return async () => {
    try {
      const url = process.env.REACT_APP_FPA_API_URL + '/agronomist/' + agronomistUUID;
      const response = await rackAxiosServices.get(url);

      dispatch(fpa.actions.getFpasSuccess(response.data));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getFpasByAgronomistTeams(agronomistUUID) {
  return async () => {
    try {
      const url = process.env.REACT_APP_FPA_API_URL + '/agronomist/team/' + agronomistUUID;
      const response = await rackAxiosServices.get(url);

      dispatch(fpa.actions.getFpasSuccess(response.data));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function adminGetAllFpas() {
  return async () => {
    try {
      const url = process.env.REACT_APP_FPA_API_URL;
      const response = await rackAxiosServices.get(url);

      dispatch(fpa.actions.getFpasSuccess(response.data));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

// adding initial FPA
export function saveInitialFPA(initialFPAData) {
  return async () => {
    try {
      //console.log(`URL for posting for seed bed prep: ${process.env.REACT_APP_FPA_API_URL}`);
      //console.log(`Posting ${JSON.stringify(initialFPAData)}`);
      await rackAxiosServices.post(process.env.REACT_APP_FPA_API_URL, { ...initialFPAData });
      dispatch(fpa.actions.addInitialFPASucess(initialFPAData));

    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function updateFpaSection(fpaSectionData) {
  return async () => {
    try {
      await rackAxiosServices.post(process.env.REACT_APP_FPA_API_URL + '/update', { ...fpaSectionData });
      // dispatch(fpa.actions.postFpaSectionSuccess(fpaSectionData));
      dispatch(fpa.actions.updateFpaSuccess(fpaSectionData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function saveSectionAndGetScore(fpaSectionData) {
  return async () => {
    try {
      //console.log('posting to subsection: ', fpaSectionData.subsection);
      //console.log('Posting  ', JSON.stringify(fpaSectionData));

      const response = await rackAxiosServices.post(process.env.REACT_APP_BUS_LOGIC_URL + '/' + fpaSectionData.subsection, { ...fpaSectionData });
      //console.log(`The response from the service is ${JSON.stringify(response.data)}`);
      const scoreData = {
        score: response.data.businessLogicResponseScore,
        section: fpaSectionData.section,
        subsection: fpaSectionData.subsection
      }
      dispatch(fpa.actions.getScoreSuccess(scoreData));
      dispatch(fpa.actions.saveFpaSection(fpaSectionData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}


export function saveSubsectionAndGetScore(fpaSubsectionData) {
  return async () => {
    try {
      //console.log(`URL for posting for seed bed prep: ${process.env.REACT_APP_BUS_LOGIC_URL}`);
      //console.log(`Posting ${JSON.stringify(fpaSubsectionData)}`);
      //console.log('url: ', process.env.REACT_APP_BUS_LOGIC_URL + '/' + fpaSubsectionData.subsection);
      const response = await rackAxiosServices.post(process.env.REACT_APP_BUS_LOGIC_URL + '/' + fpaSubsectionData.subsection, { ...fpaSubsectionData });
      //console.log(`The response from the service is ${JSON.stringify(response.data)}`);
      const scoreData = {
        score: response.data.businessLogicResponseScore,
        section: fpaSubsectionData.section,
        subsection: fpaSubsectionData.subsection
      }
      dispatch(fpa.actions.getScoreSuccess(scoreData));
      dispatch(fpa.actions.saveFpaSubsection(fpaSubsectionData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getFormTimePlacementScore(ftpData) {
  return async () => {
    try {
      //console.log(`URL for posting for seed bed prep: ${process.env.REACT_APP_BUS_LOGIC_URL}`);
      //console.log(`Posting ${JSON.stringify(ftpData)}`);
      //console.log('url: ', process.env.REACT_APP_BUS_LOGIC_URL + '/' + ftpData.subsection);
      const response = await rackAxiosServices.post(process.env.REACT_APP_BUS_LOGIC_URL + '/' + ftpData.subsection, { ...ftpData });
      //console.log(`The response from the service is ${JSON.stringify(response.data)}`);
      const scoreData = {
        score: response.data.businessLogicResponseScore,
        section: ftpData.section,
        subsection: ftpData.subsection
      }
      dispatch(fpa.actions.getScoreSuccess(scoreData));
      dispatch(fpa.actions.saveFpaSubsection(ftpData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function saveSection(fpaSectionData) {
  return async () => {
    try {
      dispatch(fpa.actions.saveFpaSection(fpaSectionData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function saveTotalScore(scoreData) {
  return async () => {
    try {
      dispatch(fpa.actions.saveTotalScore(scoreData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function resetScores(scoreData) {
  return async () => {
    try {
      dispatch(fpa.actions.resetScores(scoreData));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getYieldDataPrediction(yieldData) {
  return async () => {
    try {
      //console.log(`URL for posting yield prediction: ${process.env.REACT_APP_BUS_LOGIC_URL}`);
      //console.log('Posting  ', JSON.stringify(yieldData));

      const response = await rackAxiosServices.post(process.env.REACT_APP_BUS_LOGIC_URL + '/yield', { ...yieldData });

      //console.log(`The response from the service is ${JSON.stringify(response.data)}`);
      const yieldPrediction = {
        prediction: response.data.yieldResponse,
      }
      dispatch(fpa.actions.getYieldDataPredictionSuccess(yieldPrediction));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function getZoneYieldDataPrediction(yieldData) {
  return async () => {
    try {
      //console.log(`URL for posting yield prediction: ${process.env.REACT_APP_BUS_LOGIC_URL}`);
      //console.log('Posting  ', JSON.stringify(yieldData));

      const response = await rackAxiosServices.post(process.env.REACT_APP_BUS_LOGIC_URL + '/yield', { ...yieldData });

      //console.log(`The response from the service is ${JSON.stringify(response.data)}`);
      // update predictions array
      yieldData.predictions[yieldData.zoneNumber] = response.data.yieldResponse.yieldPrediction;
      dispatch(fpa.actions.updatePredictions(yieldData.predictions));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}

export function deletePrediction(yieldData) {
  return async () => {
    try {
      // update predictions array
      yieldData.predictions.splice(yieldData.zoneNumber, 1);
      dispatch(fpa.actions.updatePredictions(yieldData.predictions));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(fpa.actions.hasError(axiosError));
    }
  };
}