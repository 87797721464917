// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  FileProtectOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  HomeOutlined, 
  SolutionOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  FileProtectOutlined,
  HomeOutlined, 
  SolutionOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //


const fpaMenu = {
  id: 'fpaMenu',
  title: <FormattedMessage id="fpaMenu" />,
  type: 'group',
  children: [
  {
      id: 'fpa-form',
      title: <FormattedMessage id="fpa-form" />,
      type: 'item',
      url: '/fpa-list-view/fpa-form/:fpaId',
      icon: icons.FileProtectOutlined
    }
  ]
};



export default fpaMenu;
