import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js';

import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminCreateUserCommand,
  AdminDeleteUserCommand,
  AdminUpdateUserAttributesCommand,
  ListGroupsCommand,
  ListUsersInGroupCommand,
  AdminGetUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminResetUserPasswordCommand,
  AdminSetUserPasswordCommand,
  AdminRemoveUserFromGroupCommand,
  AdminAddUserToGroupCommand
} from '@aws-sdk/client-cognito-identity-provider';
import jwt_decode from 'jwt-decode';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project imports
import Loader from 'components/Loader';
import { AWS_API } from 'config';
import useScriptRef from 'hooks/useScriptRef';

//AWS Amplify Auth
import { Auth } from 'aws-amplify';

//import casl abilities and rule definitions
import { ForbiddenError, Ability } from '@casl/ability';
const { getRoleAbilityForUser } = require('../utils/casl/abilities');
const { PERMISSIONS, MODEL_NAMES } = require('../utils/casl/roles');

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

export const userPool = new CognitoUserPool({
  UserPoolId: AWS_API.poolId || '',
  ClientId: AWS_API.appClientId || ''
});

// Load the AWS SDK
const AWS = require('aws-sdk');

//Initialize the Amazon Cognito credentials provider
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: AWS_API.identityPoolId
});
AWS.config.region = AWS_API.region; // Region

AWS.config.apiVersions = {
  cognitoidentityserviceprovider: '2016-04-18'
  // other service API versions
};
const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

const setSession = (serviceToken, idToken, refreshToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
  } else {
    localStorage.removeItem('serviceToken');
  }

  if (idToken) {
    localStorage.setItem('idToken', idToken);
  } else {
    localStorage.removeItem('idToken');
  }

  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

//Add listeners to keep track of user activity
document.addEventListener('mousemove', () => {
  localStorage.setItem('lastActivity', new Date());
});
document.addEventListener('click', () => {
  localStorage.setItem('lastActivity', new Date());
});

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const scriptedRef = useScriptRef();
  const [signedinUser, setSignedinUser] = useState();
  const navigate = useNavigate();
  let [accessKeyId, setAccessKeyId] = useState('');
  let [secretAccessKey, setSecretAccessKey] = useState('');
  let [sessionToken, setSessionToken] = useState('');

  const client = new CognitoIdentityProviderClient({
    region: AWS_API.region,
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    }
  });

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        const idToken = window.localStorage.getItem('idToken');
        const refreshToken = window.localStorage.getItem('refreshToken');

        var cognitoUser = userPool.getCurrentUser();

        if (serviceToken && idToken && refreshToken) {
          if (cognitoUser != null) {
            // Add the User's Id Token to the Cognito credentials login map.
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: AWS_API.identityPoolId,
              Logins: {
                [AWS_API.providerLogin]: idToken
              }
            });

            //Grabs the credentials from AWS.config.credentials, and sets them to the global variables defined
            getCredentialsForToken(AWS)
              .then((res) => {
                setAccessKeyId(res.accessKeyId);
                setSecretAccessKey(res.secretAccessKey);
                setSessionToken(res.sessionToken);

                //Retreive all the user attributes from cognito, which will be needed to display their information throughout the app
                getCognitoUserDetailedAttributes(cognitoUser, res.accessKeyId, res.secretAccessKey, res.sessionToken);
              })
              .catch((e) => console.log('1'+ e.message));
          }
        } else {
          logout();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        logout();
        dispatch({
          type: LOGOUT
        });
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  // Fetches / creates cogintio id creds,
  // auto refreshes any existing creds before returning them
  const getCredentialsForToken = async (AWS) => {
    //console.log('fetching creds');
    return AWS.config.credentials
      .getPromise()
      .then((error) => {
        if (error) {
          return Promise.reject('5'+ error);
        }
        if (AWS.config.credentials.needsRefresh() === true) {
          return AWS.config.credentials.refreshPromise();
        }
        return null;
      })
      .then((error) => {
        if (error) {
          return Promise.reject('6'+ error);
        }
        //grab creds
        const { accessKeyId = '', secretAccessKey = '', sessionToken = '' } = AWS.config.credentials;

        return { accessKeyId, secretAccessKey, sessionToken };
      });
  };

  const checkTokenExpiration = async () => {
    const idToken = window.localStorage.getItem('idToken');

    if (idToken) {
      //If the id token for the user has expired, then refresh the token. 
      const { exp } = jwt_decode(idToken);
      const expirationTime = new Date(exp * 1000);
      const dateNow = new Date();
      //console.log('expirationTime', expirationTime);

      if (dateNow >= expirationTime - 1) {
        console.log('Id Token has expired. Refreshing Token');
        await refreshTokens();
      }
    }
  };

  //If the user has no activity on the app, then automatically log them off after 2 hours
  let userActivityTimeInterval = setInterval(() => {
    const loggedInUser = userPool.getCurrentUser();

    try{
      if (loggedInUser) {
        //call checkTokenExpiration function to determine if the users token is still valid or not
        checkTokenExpiration();
  
        let lastAcivity = localStorage.getItem('lastActivity');
        let diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
        let seconds = Math.floor(diffMs / 1000);
        let minute = Math.floor(seconds / 60);
  
        if (minute >= 120) {
          alert('You have been logged out due to inactivity, please log in again.');
          clearInterval(userActivityTimeInterval);
          logout();
          dispatch({
            type: LOGOUT
          });
        }
      }
    }
    catch (err) {
      console.error('2'+ err);
    }
  }, 1000);

  const login = async (email, password) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    const authData = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    return new Promise((success, rej) => {
      usr.authenticateUser(authData, {
        onSuccess: (session) => {
          success('Succesfully Logged In');

          const serviceToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
          const refreshToken = session.getRefreshToken().getToken();

          setSession(serviceToken, idToken, refreshToken);

          var cognitoUser = userPool.getCurrentUser();
          //console.log('Logging in Cognito User', cognitoUser);
          
          if (cognitoUser != null) {
            // Add the user's Id Token to the Cognito credentials login map.
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: AWS_API.identityPoolId,
              Logins: {
                [AWS_API.providerLogin]: idToken
              }
            });
          
            //Grabs the credentials from AWS.config.credentials, and sets them to the global variables defined
            getCredentialsForToken(AWS).then((res)=>{
              setAccessKeyId(res.accessKeyId);
              setSecretAccessKey(res.secretAccessKey);
              setSessionToken(res.sessionToken);

              //Retreive all the user attributes from cognito, which will be needed to display their information throughout the app
              getCognitoUserDetailedAttributes(cognitoUser, res.accessKeyId, res.secretAccessKey, res.sessionToken);
            }).catch(e => console.log('3'+ e.message));
          }
        },
        onFailure: err => {
          rej(err)
        },
        newPasswordRequired: () => {
          success("New Password Required");
        }
      });
    });
  };

  const getCognitoUserDetailedAttributes = async (cognitoUser, accessKeyId, secretAccessKey, sessionToken) => {
    var signedinUser = {};

    cognitoUser.getSession(async function (err, session) {
      if (session) {
        const client = new CognitoIdentityProviderClient({
          region: AWS_API.region,
          credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            sessionToken: sessionToken
          }
        });

        const params = {
          UserPoolId: AWS_API.poolId,
          Username: cognitoUser.username
        };

        const command = new AdminGetUserCommand(params);

        try {
          const response = await client.send(command);

          for (let i = 0; i < response.UserAttributes.length; i++) {
            signedinUser[response.UserAttributes[i].Name] = response.UserAttributes[i].Value;
            //console.log(response.UserAttributes[i].Name, response.UserAttributes[i].Value);
          }

          //Need to assign the status to the user
          signedinUser['accountStatus'] = response.Enabled;

          if(response.UserStatus === 'FORCE_CHANGE_PASSWORD'){
            signedinUser['userStatus'] = 'Pending';
          }else if(response.Enabled === false){
            signedinUser['userStatus'] = 'Disabled';
          }
          else{
            signedinUser['userStatus'] = 'Active';
          }


          //Need to get the users cognito group from the users idtoken and set it as the 'role' attribute for the signed in user
          signedinUser['role'] = session.getIdToken().payload['cognito:groups'][0];

          //Update Casl role abilities to the user
          const ability = getRoleAbilityForUser(signedinUser);
          signedinUser.abilities = ability.rules;

          const userAbility = new Ability();
          userAbility.update(signedinUser.abilities);
          signedinUser.userAbility = userAbility;

          setSignedinUser(signedinUser);
          //console.log('signedinUser', signedinUser);
          localStorage.setItem('loggedInUser', JSON.stringify(signedinUser));
        } catch (err) {
          console.error(err);
        }
      } else {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: signedinUser
      }
    });
  };

  const refreshTokens = async () => {
    const rToken = window.localStorage.getItem('refreshToken');
    const cognitoUser = userPool.getCurrentUser();

    const token = new CognitoRefreshToken({ RefreshToken: rToken });

    if (cognitoUser != null) {
      cognitoUser.refreshSession(token, function (err, session) {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          return;
        } else {
          //console.log('Refreshing all Tokens');

          const serviceToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
          const refreshToken = session.getRefreshToken().getToken();

          setSession(serviceToken, idToken, refreshToken);

          // Add the user's new Id Token to the Cognito credentials login map.
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AWS_API.identityPoolId,
            Logins: {
              [AWS_API.providerLogin]: idToken
            }
          });
          //Grabs the credentials from AWS.config.credentials, and sets them to the global variables defined
          getCredentialsForToken(AWS)
            .then((res) => {
              setAccessKeyId(res.accessKeyId);
              setSecretAccessKey(res.secretAccessKey);
              setSessionToken(res.sessionToken);
            })
            .catch((e) => console.err('4'+ e.message));
        }
      });
    }
  };

  const firstTimePasswordChange = async (email, oldPassword, newPassword) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    const authData = new AuthenticationDetails({
      Username: email,
      Password: oldPassword
    });

    return new Promise((success, rej) => {
      usr.authenticateUser(authData, {
        onSuccess: () => {},
        onFailure: (err) => {
          rej(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete authentication.

          // The api doesn't accept these fields back
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;

          // Get these details and call
          usr.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (result) => {
                if (scriptedRef.current) {
                  success('New Password Entered');
                  setSession(null, null, null);
                }
              },
              onFailure: (err) => {
                rej(err);
              },
              newPasswordRequired: (userAttributes, requiredAttributes) => {
                delete userAttributes.email_verified;
                delete userAttributes.phone_number_verified;

                usr.completeNewPasswordChallenge(newPw, userAttributes, this.newPasswordRequired);
              }
            }
          );
        }
      });
    });
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser != null) {
        return new Promise((success, rej) => {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            cognitoUser.setSignInUserSession(session);
            cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
              if (err) {
                rej(err);
                return;
              } else {
                success(result);
              }
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateProfile = async (firstName, lastName, phone, location) => {
    try {
      const cognitoUser = userPool.getCurrentUser();

      const attributeList = [];
      attributeList.push(new CognitoUserAttribute({ Name: 'name', Value: firstName + ' ' + lastName }));
      attributeList.push(new CognitoUserAttribute({ Name: 'given_name', Value: firstName }));
      attributeList.push(new CognitoUserAttribute({ Name: 'family_name', Value: lastName }));
      attributeList.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phone }));
      attributeList.push(new CognitoUserAttribute({ Name: 'locale', Value: location }));

      if (cognitoUser != null) {
        return new Promise((success, rej) => {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            cognitoUser.setSignInUserSession(session);
            cognitoUser.updateAttributes(attributeList, function (err, result) {
              if (err) {
                rej(err);
                //alert(err.message || JSON.stringify(err));
                return;
              } else {
                success(result);
                setSignedinUser(cognitoUser);
              }
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getUser = async () => {
    try {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser != null) {
        //Retreive all the user attributes from cognito, which will be needed to display their information throughout the app
        getCognitoUserDetailedAttributes(cognitoUser, accessKeyId, secretAccessKey, sessionToken);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getUsers = async () => {
    await refreshTokens();

    const Users = [];

    const params = {
      UserPoolId: AWS_API.poolId
    };

    const getAllUsersCommand = new ListUsersCommand(params);
    const getGroupsCommand = new ListGroupsCommand(params);

    //Get all the groups in the user pool
    const data = await client.send(getGroupsCommand);

    try {
      for (var k = 0; k < data.Groups.length; k++) {
        const params2 = {
          UserPoolId: AWS_API.poolId,
          GroupName: data.Groups[k].GroupName
        };
        const getUsersInGroupCommand = new ListUsersInGroupCommand(params2);

        //Get all the users in each group
        const data2 = await client.send(getUsersInGroupCommand);
        //console.log(`${data.Groups[k].GroupName} has ${data2.Users.length} users`);

        for (var l = 0; l < data2.Users.length; l++) {
          const User = {};
          for (var m = 0; m < data2.Users[l].Attributes.length; m++) {
            User[data2.Users[l].Attributes[m].Name] = data2.Users[l].Attributes[m].Value;
            //console.log(data2.Users[l].Attributes[m].Name, data2.Users[l].Attributes[m].Value);
          }

          //Need to assign the status' to the user from the cognito meta data
          User['accountStatus'] = data2.Users[l].Enabled;

          if(data2.Users[l].UserStatus === 'FORCE_CHANGE_PASSWORD'){
            User['userStatus'] = 'Pending';
          }else if(data2.Users[l].Enabled === false){
            User['userStatus'] = 'Disabled';
          }
          else{
            User['userStatus'] = 'Active';
          }

          //Add the the Group name as their 'role' attribute
          User['role'] = data.Groups[k].GroupName;
          Users.push(User);
        }
      }
      return Users;
    } catch (err) {
      console.error(err);
    }
  };

  const register = (email, password, firstName, lastName) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
          new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
          new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const adminAddUser = async (newUser) => {
    const params = {
      UserPoolId: AWS_API.poolId,
      Username: newUser.email,
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: false,
      GroupName: newUser.role,
      UserAttributes: [
        { Name: 'email', Value: newUser.email },
        { Name: 'name', Value: `${newUser.firstName} ${newUser.lastName}` },
        { Name: 'phone_number', Value: newUser.phone_number },
        { Name: 'given_name', Value: newUser.firstName },
        { Name: 'family_name', Value: newUser.lastName },
        { Name: 'locale', Value: newUser.location },
        { Name: 'email_verified', Value: 'true' },
        { Name: 'phone_number_verified', Value: 'true' }
      ]
    };

    const ability = getRoleAbilityForUser(signedinUser);

    if (ability.can(PERMISSIONS.CREATE, MODEL_NAMES.USER)) {
      const command = new AdminCreateUserCommand(params);
      const addUserToGroupCmd = new AdminAddUserToGroupCommand(params);

      try {
        const response = await client.send(command);
        await client.send(addUserToGroupCmd);
        //console.log(response);
        return response;
      } catch (err) {
        console.error(err);
        return error;
      }
    } else {
      throw new ForbiddenError('Does not have authorized access');
    }
  };

  const adminUpdateUser = async (updatedUser, existingUserRole) => {
    const params = {
      UserPoolId: AWS_API.poolId,
      Username: updatedUser.email,
      UserAttributes: [
        { Name: 'email', Value: updatedUser.email },
        { Name: 'name', Value: `${updatedUser.firstName} ${updatedUser.lastName}` },
        { Name: 'phone_number', Value: updatedUser.phone_number },
        { Name: 'given_name', Value: updatedUser.firstName },
        { Name: 'family_name', Value: updatedUser.lastName },
        { Name: 'locale', Value: updatedUser.location },
      ]
    };

    const command = new AdminUpdateUserAttributesCommand(params);

    try {
      if (existingUserRole === updatedUser.role)
      {
        const response = await client.send(command);
      //console.log(response);
      return response;
      }
      else{
      //If the user is already in a group and you want to move them to another group, you can use the AdminRemoveUserFromGroup method to remove the user from the old group,
      //and then use the AdminAddUserToGroup method to add them to the new group.
      
      const oldGroupName = existingUserRole;
      const newGroupName = updatedUser.role;

      // Remove the user from the old group
      const removeParams = {
        UserPoolId: AWS_API.poolId,
        Username: updatedUser.email,
        GroupName: oldGroupName,
      };
      const removeCommand = new AdminRemoveUserFromGroupCommand(removeParams);

      client.send(removeCommand)
        .then((data) => {
          console.log("User removed from old group:", data);

          // Add the user to the new group
          const addParams = {
            UserPoolId: AWS_API.poolId,
            Username: updatedUser.email,
            GroupName: newGroupName,
          };
          const addCommand = new AdminAddUserToGroupCommand(addParams);

          client.send(addCommand)
            .then((data) => {
              console.log("User added to new group:", data);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });

      const response = await client.send(command);
      //console.log(response);

      return response;
    }
    } catch (err) {
      console.error(err);
    }
  };

  //Do not use this, as we dont want to permanently delete users in prod.
  //Instead use the adminArchiveUser function
  const adminDeleteUser = async (email) => {
    const adminDeleteUserCommandInput = {
      UserPoolId: AWS_API.poolId,
      Username: email
    };

    const command = new AdminDeleteUserCommand(adminDeleteUserCommandInput);

    try {
      const response = await client.send(command);
      //console.log(response);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const adminDisableUser = async (email) => {
    const adminDisableUserCommandInput = {
      UserPoolId: AWS_API.poolId,
      Username: email
    };

    const command = new AdminDisableUserCommand(adminDisableUserCommandInput);

    try {
      const response = await client.send(command);
      //console.log(response);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const adminEnableUser = async (email) => {
    const adminEnableUserCommandInput = {
      UserPoolId: AWS_API.poolId,
      Username: email
    };

    const command = new AdminEnableUserCommand(adminEnableUserCommandInput);

    try {
      const response = await client.send(command);
      //console.log(response);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const adminResetUserPassword = async (email) => {
    const adminResetUserPasswordCommandInput = {
      UserPoolId: AWS_API.poolId,
      Username: email
    };

    const userStatusCommand = new AdminGetUserCommand(adminResetUserPasswordCommandInput);
    const resetPasswordCommand = new AdminResetUserPasswordCommand (adminResetUserPasswordCommandInput);

    try {
      //First check the users status to see if they are able to have their password reset
      const response = await client.send(userStatusCommand);

      //Users in a force change password state will not be able to reset their password.
      //This can happen if the user is required to change their password after their first sign-in or if their password has expired.
      //In this case, you can use the AdminSetUserPasswordCommand command to set a new password for the user.
      if(response.UserStatus==='FORCE_CHANGE_PASSWORD')
      {
        return response.UserStatus;
      }
      else{
        const response = await client.send(resetPasswordCommand);
        //console.log(response);
        return response;
      }
    } catch (err) {
      console.error(err);
    }
  };


  const adminSetUserPassword = async (email, newPassword) => {
    const adminSetUserPasswordCommandInput = {
      UserPoolId: AWS_API.poolId,
      Username: email,
      Password:newPassword,
      Permanent: false,
    };
    const command = new AdminSetUserPasswordCommand(adminSetUserPasswordCommandInput);

    try {
      const response = await client.send(command);

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const logout = () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
      setSession(null, null, null);
      localStorage.removeItem('lastActivity');
      localStorage.removeItem('loggedInUser');
      loggedInUser.signOut();
      // We need to clear the AWSCache or we can not re-login with a different user
      AWS.config.credentials.clearCachedId();
      navigate('/login', { replace: true });
      dispatch({ type: LOGOUT });
    }
  };

  const sendPasswordResetCode = (email) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    return new Promise((success, rej) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          success('Reset Verification Code Sent');
        },
        onFailure: function (err) {
          rej(err);
          //alert(err.message || JSON.stringify(err));
        }
      });
    });
  };

  const resendCode = async (code) => {
    Auth.resendSignUp(code);
  };

  const verify = async (username, code) => {
    //Auth.confirmSignUp(username, code);

    const usr = new CognitoUser({
      Username: username,
      Pool: userPool
    });

    return new Promise((success, rej) => {
      usr.confirmRegistration(code, true, function (err, result) {
        if (err) {
          rej(err);
          alert(err.message || JSON.stringify(err));
          return;
        } else {
          success('User Verified');
        }
      });
    });
  };

  const resetPassword = async (email, verificationCode, newPassword) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    return new Promise((success, rej) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (data) {
          // successfully initiated reset password request
          success('Password Changed');
        },
        onFailure: function (err) {
          rej(err);
          //alert(err.message || JSON.stringify(err));
        }
      });
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        adminAddUser,
        adminUpdateUser,
        adminDeleteUser,
        adminDisableUser,
        adminEnableUser,
        verify,
        checkTokenExpiration,
        getUser,
        getUsers,
        resetPassword,
        firstTimePasswordChange,
        resendCode,
        sendPasswordResetCode,
        changePassword,
        updateProfile,
        adminResetUserPassword,
        adminSetUserPassword,
        refreshTokens
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

AWSCognitoProvider.propTypes = {
  children: PropTypes.node
};

export default AWSCognitoContext;
