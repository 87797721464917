import axios from 'axios';

const rackAxiosServices = axios.create();

rackAxiosServices.interceptors.request.use((req) => {
  const idToken = window.localStorage.getItem('idToken');

  req.headers['Authorization'] = idToken;
  return req;
});

export default rackAxiosServices;
