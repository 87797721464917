import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import Error404 from 'pages/maintenance/404';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes(
    [
      {
        path: '*',
        element: <Error404 />,
      
      },
    
    LoginRoutes, MainRoutes]);
}
