const { AbilityBuilder, Ability } = require("@casl/ability");
const {
  defineAbilitiesForAdmin,
  defineAbilitiesForAgronomists
} = require("./roles");

const USER_ROLES = {
  ADMIN: 'Admin',
  AGRONOMIST: 'Agronomist'
};

const DEFAULT_ABILITIES = new Ability(); //defaults to no permissions

function getRoleAbilityForUser(user = {}) {
  let ability;
  switch (user.role) {
    case USER_ROLES.ADMIN:
      ability = defineAbilitiesForAdmin();
      break;
    case USER_ROLES.AGRONOMIST:
      ability = defineAbilitiesForAgronomists();
      break;
    default:
      ability = DEFAULT_ABILITIES;
      break;
  }
  return ability;
}

module.exports = {
  getRoleAbilityForUser,
};
