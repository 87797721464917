// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  FileProtectOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  HomeOutlined, 
  SolutionOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  FileProtectOutlined,
  HomeOutlined, 
  SolutionOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //


const growerMenu = {
  id: 'growerMenu',
  title: <FormattedMessage id="growerMenu" />,
  type: 'group',
  children: [
    
  {
      id: 'add-grower-form',
      title: <FormattedMessage id="add-new-grower" />,
      type: 'item',
      url: '/grower-list-view/add-new-grower',
      icon: icons.FileProtectOutlined
    },
    {
      id: 'grower-details',
      title: <FormattedMessage id="grower-details" />,
      type: 'item',
      url: '/grower-list-view/grower-details/:id',
      icon: icons.SolutionOutlined
    },
    
  ]
};



export default growerMenu;
