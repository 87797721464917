// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  FileProtectOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  HomeOutlined, 
  SolutionOutlined,
  LockOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  FileProtectOutlined,
  HomeOutlined, 
  SolutionOutlined,
  LockOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //


const profileMenu = {
  id: 'profile',
  title: <FormattedMessage id="Profile" />,
  type: 'group',
  children: [
    
    {
      id: 'user-profile',
      title: <FormattedMessage id="user-profile" />,
      type: 'item',
      url: '/account-profile/basic',
      icon: icons.SolutionOutlined
    },
    {
      id: 'user-password',
      title: <FormattedMessage id="user-password" />,
      type: 'item',
      url: '/account-profile/password',
      icon: icons.LockOutlined
    },
  ]
};



export default profileMenu;
