import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';
import { Can } from "@casl/react";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const { user } = useAuth();

  const navGroups = menuItem.items.map((item) => {

    if (user?.role !== 'Admin') {
      //Fix for profile [PY-107], we will not render the navgroup, but it's still in the menu to enable breadcrumbs
      if (item.id !== 'profile' && item.id !== 'adminMenu' && item.id !== 'fpaMenu' && item.id !== 'growerMenu') {
        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Fix - Navigation Group No Esta
              </Typography>
            );
        }
      }
    }
    else{
      //Fix for profile [PY-107], we will not render the navgroup, but it's still in the menu to enable breadcrumbs
      if (item.id !== 'profile' && item.id !== 'fpaMenu' && item.id !== 'growerMenu') {
        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Fix - Navigation Group No Esta
              </Typography>
            );
        }
      }
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
