const { AbilityBuilder, Ability } = require("@casl/ability");

const PERMISSIONS = {
  MANAGE: "manage",
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
};

const MODEL_NAMES = { 
  USER: "User",
  GROWER: "Grower"
};

const defineAbilitiesForAdmin = () => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  can(PERMISSIONS.MANAGE, MODEL_NAMES.USER);
  can(PERMISSIONS.CREATE, MODEL_NAMES.USER);
  can(PERMISSIONS.READ, MODEL_NAMES.USER);
  can(PERMISSIONS.UPDATE, MODEL_NAMES.USER);
  can(PERMISSIONS.DELETE, MODEL_NAMES.USER);

  can(PERMISSIONS.MANAGE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.CREATE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.READ, MODEL_NAMES.GROWER);
  can(PERMISSIONS.UPDATE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.DELETE, MODEL_NAMES.GROWER);

  return new Ability(rules);
};

const defineAbilitiesForAgronomists = () => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  
  can(PERMISSIONS.MANAGE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.CREATE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.READ, MODEL_NAMES.GROWER);
  can(PERMISSIONS.UPDATE, MODEL_NAMES.GROWER);
  can(PERMISSIONS.DELETE, MODEL_NAMES.GROWER);

  return new Ability(rules);
};



module.exports = {
  PERMISSIONS,
  MODEL_NAMES,
  defineAbilitiesForAdmin,
  defineAbilitiesForAgronomists
};
