import { createSlice } from '@reduxjs/toolkit';

// project imports
import rackAxiosServices from 'utils/rackAxiosServices';

import { dispatch } from '../index';

const initialState = {
  error: null,
  growerGeneralInfo: {},
  equipmentInformation: {},
  seedInfo: {},
  yieldInfo: {},
  scoutTestInfo: {},
  growers: [],
  groweruuid: '',
  agronomistuuid: ''
};

const grower = createSlice({
  name: 'grower',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload.error;
    },
    //Clear current grower from state, since we are working on a new grower
    clearCurrentGrowerData(state, action) {
      state.growerGeneralInfo = undefined;
      state.groweruuid = undefined;
      state.equipmentInformation = undefined;
      state.seedInfo = undefined;
      state.yieldInfo = undefined;
      state.scoutTestInfo = undefined;
    },

    // UPDATE Grower creater field
    updateGrowerCreatorSuccess(state, action) {
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;
      state.createdBy = action.payload.createdBy;
    },
    // ADD General Information
    addGeneralInformationSuccess(state, action) {
      state.growerGeneralInfo = action.payload.growerGeneralInfo;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;
    },
    // UPDATE General Information
    updateGeneralInformationSuccess(state, action) {
      state.growerGeneralInfo = action.payload.updateInformation;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.groweruuid = action.payload.groweruuid;
    },
    // ADD Equip Information
    addEquipmentInformationSuccess(state, action) {
      state.equipmentInformation = action.payload.equipmentInformation;
    },
    // UPDATE Equip Information
    updateEquipmentInformationSuccess(state, action) {
      state.equipmentInformation = action.payload.updateInformation;
    },
    // ADD Seeding Information
    addSeedingInformationSuccess(state, action) {
      state.seedInfo = action.payload.seedInfo;
    },
    // UPDATE Seeding Information
    updateSeedingInformationSuccess(state, action) {
      state.seedInfo = action.payload.updateInformation;
    },
    // ADD Yield Information
    addYieldInformationSuccess(state, action) {
      state.yieldInfo = action.payload.yieldInfo;
    },
    // UPDATE Yield Information
    updateYieldInformationSuccess(state, action) {
      state.yieldInfo = action.payload.updateInformation;
    },
    // ADD Scout Test Information
    addScoutTestInformationSuccess(state, action) {
      state.scoutTestInfo = action.payload.scoutTestInfo;
    },
    // UPDATE Scout Test Information
    updateScoutTestInformationSuccess(state, action) {
      state.scoutTestInfo = action.payload.updateInformation;
    },
    // GET All Growers
    getGrowersSuccess(state, action) {
      state.growers = action.payload;
      state.error = null;
    },

    getGrowerSuccess(state, action) {
      state.groweruuid = action.payload.groweruuid;
      state.agronomistuuid = action.payload.agronomistuuid;
      state.growerGeneralInfo = action.payload.growerGeneralInfo;
      state.equipmentInformation = action.payload.equipmentInformation;
      state.yieldInfo = action.payload.yieldInfo;
      state.seedInfo = action.payload.seedInfo;
      state.scoutTestInfo = action.payload.scoutTestInfo;
      state.error = null;
    }
  }
});

// Reducer
export default grower.reducer;
//Export action for dispatch usage
export const { clearCurrentGrowerData } = grower.actions;

export function getGrower(growerUuid) {
  return async () => {
    try {
      const url = process.env.REACT_APP_GROWER_API_URL + '/' + growerUuid;
      const response = await rackAxiosServices.get(url);
      dispatch(grower.actions.getGrowerSuccess(response.data[0]));

    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function getGrowersByAgronomist(agronomistUUID) {
  return async () => {
    try {
      const url = process.env.REACT_APP_GROWER_API_URL + '/agronomist/' + agronomistUUID;
      const response = await rackAxiosServices.get(url);
      dispatch(grower.actions.getGrowersSuccess(response.data));
    } catch (error) {
      console.log(`Error from axios call ${JSON.stringify(error)}`);
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function getGrowersByAgronomistTeams(agronomistUUID) {
  return async () => {
    try {
      const url = process.env.REACT_APP_GROWER_API_URL + '/agronomist/team/' + agronomistUUID;
      const response = await rackAxiosServices.get(url);
      dispatch(grower.actions.getGrowersSuccess(response.data));
    } catch (error) {
      console.log(`Error from axios call ${JSON.stringify(error)}`);
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function adminGetAllGrowers() {
  return async () => {
    try {
      const url = process.env.REACT_APP_GROWER_API_URL;
      const response = await rackAxiosServices.get(url);
      dispatch(grower.actions.getGrowersSuccess(response.data));
    } catch (error) {
      console.log(`Error from axios call ${JSON.stringify(error)}`);
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function saveGrowerGeneralInformation(generalInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(generalInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL, { ...generalInfo });
      dispatch(grower.actions.addGeneralInformationSuccess(generalInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function saveGrowerEquipmentInformation(equipmentInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(equipmentInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...equipmentInfo });
      dispatch(grower.actions.addEquipmentInformationSuccess(equipmentInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function saveGrowerSeedingInformation(seedInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(seedInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...seedInfo });
      dispatch(grower.actions.updateSeedingInformationSuccess(seedInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function saveGrowerYieldInformation(yieldInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(yieldInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...yieldInfo });
      dispatch(grower.actions.addYieldInformationSuccess(yieldInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function saveGrowerScoutTestInformation(scoutTestInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(scoutTestInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...scoutTestInfo });
      dispatch(grower.actions.addScoutTestInformationSuccess(scoutTestInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

//Only use this api call if you need to update the top level data for a grower, which for now will only be to update the 'createdBy' field
export function updateGrower(info) {
  return async () => {
    try {
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/updateGrowerCreater', { ...info });
      dispatch(grower.actions.updateGrowerCreatorSuccess(info));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function updateGrowerGeneralInformation(generalInfo) {
  return async () => {
    try {
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...generalInfo });
      dispatch(grower.actions.updateGeneralInformationSuccess(generalInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function updateGrowerEquipmentInformation(equipmentInfo) {
  return async () => {
    try {
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...equipmentInfo });
      dispatch(grower.actions.updateEquipmentInformationSuccess(equipmentInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function updateGrowerSeedingInformation(seedInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(seedInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...seedInfo });
      dispatch(grower.actions.updateSeedingInformationSuccess(seedInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function updateGrowerYieldInformation(yieldInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(yieldInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...yieldInfo });
      dispatch(grower.actions.updateYieldInformationSuccess(yieldInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}

export function updateGrowerScoutTestInformation(scoutTestInfo) {
  return async () => {
    try {
      //console.log(`URL for posting: ${process.env.REACT_APP_GROWER_API_URL}`);
      //console.log(`Posting ${JSON.stringify(scoutTestInfo)}`);
      await rackAxiosServices.post(process.env.REACT_APP_GROWER_API_URL + '/update', { ...scoutTestInfo });
      dispatch(grower.actions.updateScoutTestInformationSuccess(scoutTestInfo));
    } catch (error) {
      const axiosError = {
        message: error.message,
        status: error.status,
        code: error.code
      };
      dispatch(grower.actions.hasError(axiosError));
    }
  };
}
