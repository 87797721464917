// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  FileProtectOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  HomeOutlined, 
  SolutionOutlined,
  ProfileOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  FileProtectOutlined,
  HomeOutlined, 
  SolutionOutlined,
  ProfileOutlined,
  UserAddOutlined,
  UsergroupAddOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    
    {
      id: 'home',
      title: <FormattedMessage id="home" />,
      type: 'item',
      url: '/home',
      icon: icons.HomeOutlined
    },
    {
      id: 'grower-list-view',
      title: <FormattedMessage id="grower-list-view"/>,
      type: 'item',
      url: '/grower-list-view',
      icon: icons.SolutionOutlined  ,
        
    },  
    {
      id: 'fpa-list-view',
      title: <FormattedMessage id="fpa-list-view" />,
      type: 'item',
      url: '/fpa-list-view',
      icon: icons.FileProtectOutlined,
    
    }
  
   
  ]
};

export default other;
