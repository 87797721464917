import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page
const HomePage = Loadable(lazy(() => import('pages/home/home')));

// render - app pages
const GrowerListView = Loadable(lazy(() => import('pages/grower/grower-list-view')));
const FPAsPage = Loadable(lazy(() => import('pages/fpa/fpa-list-view')));
// render - fpa score pages
const FPAFormPage = Loadable(lazy(() => import('pages/fpa/fpa-form')));
const TabSeedBedPrep = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabSeedBedPrep')));
const TabNutrition = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabNutrition')));
const TabSeedQuality = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabSeedQuality')));
const TabSeedDepth = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabSeedDepth')));
const TabSeedDate = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabSeedDate')));
const TabPestControl = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabPestControl')));
const TabFinalScore = Loadable(lazy(() => import('sections/fpa-list/fpa-form/TabFinalScore')));

//render - grower pages
const GrowerDetails = Loadable(lazy(() => import('pages/grower/grower-details')));
const GrowerFormPage = Loadable(lazy(() => import('pages/grower/grower-form')));
const TabGrowerGeneralInfo = Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerGeneralInfo')));
const TabGrowerEquipment = Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerEquipment')));
const TabGrowerScoutTest = Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerScoutTest')));
const TabGrowerYield = Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerYield')));
const TabGrowerSeeding = Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerSeeding')));
const TabGrowerFPAs =  Loadable(lazy(() => import('sections/growers-list/grower-form/TabGrowerFPAs')));



//render -user profile pages
const AccountProfile = Loadable(lazy(() => import ('pages/user-profile/account-profile')));
const AccountTabProfile = Loadable(lazy(() => import('sections/user-profile-tabs/account/TabProfile')));
const AccountTabPassword = Loadable(lazy(() => import('sections/user-profile-tabs/account/TabPassword')));

//render -admin pages
const UserListPage = Loadable(lazy(() => import('pages/admin/user-list-view')));

//Error page
const ErrorPage = Loadable(lazy(() => import('pages/maintenance/500')));
const PageNotFound = Loadable(lazy(() => import('pages/maintenance/404')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <HomePage />
        },
        {
          path: 'error',
          element: <ErrorPage />
        },
       
        {
          path: 'grower-list-view',
          element: <GrowerListView/>
        },
        {
          path: 'grower-list-view/add-new-grower',
          element: <GrowerFormPage />,
          children: [
            {
              path: 'grower-general-info',
              element: <TabGrowerGeneralInfo />
            },
            {
              path : 'grower-equipment',
              element: <TabGrowerEquipment />
            },
            {
              path: 'grower-yield',
              element: <TabGrowerYield />
            },
            {
              path: 'grower-scout-and-test',
              element: <TabGrowerScoutTest />
            },
            {
              path: 'grower-seeding-info',
              element: <TabGrowerSeeding />
            }
          ]
        },
        {
          path: 'grower-list-view/grower-details/:id',
          element: <GrowerDetails />,
          children: [
            {
              path: 'grower-general-info',
              element: <TabGrowerGeneralInfo />
            },
            {
              path : 'grower-equipment-info',
              element: <TabGrowerEquipment />
            },
            {
              path: 'grower-yield-info',
              element: <TabGrowerYield />
            },
            {
              path: 'grower-scout-and-test',
              element: <TabGrowerScoutTest />
            },
            {
              path: 'grower-seeding-info',
              element: <TabGrowerSeeding />
            },
            {
              path: 'grower-fpas',
              element: <TabGrowerFPAs />
            }
          ]
        },
        {
          path: 'fpa-list-view',
          element: <FPAsPage />,
      
        },
        {
          path: '/fpa-list-view/fpa-form/:fpaId',
          element: <FPAFormPage />,
          children: [
            {
              path: 'seed-bed-prep',
              element: <TabSeedBedPrep  />
            },
            {
              path: 'nutrition',
              element: <TabNutrition />
            },
            {
              path: 'seed-quality',
              element: <TabSeedQuality />
            },
            {
              path: 'seed-depth',
              element: <TabSeedDepth />
            },
            {
              path: 'seed-date',
              element: <TabSeedDate />
            },
            {
              path: 'pest-control',
              element: <TabPestControl />
            },
          
            {
              path: 'fpa-summary',
              element: <TabFinalScore />
            }
          ]
        },
        {
          path: 'account-profile',
          element: <AccountProfile />,
          children: [
            {
              path: 'basic',
              element: <AccountTabProfile />
            },
            {
              path: 'password',
              element: <AccountTabPassword />
            }
          ]
        },
        {
          path:'user-list-view',
          element: <UserListPage/>
        }
      ]
      
    },
    
  ]
};

export default MainRoutes;
 